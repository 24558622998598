.btnJoin {
  padding: 0.7em 0;
  border: none;
  border-radius: 5px;
  background: var(--color-blue);
  font-size: 1.5em;
  font-weight: 700;
  color: var(--color-white);
  box-shadow: 0 4px 0 var(--color-shadow-box);
  cursor: pointer;
  grid-area: btnJoin;
  transition: none;

  &:hover {
    background: var(--color-blue-dark);
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0 2px 0 var(--color-shadow-box);
  }

  &:disabled {
    transform: translateY(2px);
    box-shadow: 0 2px 0 var(--color-shadow-box);
    background: var(--color-blue-dark);
  }
}
