.statusCreate {
  color: var(--color-red);
  line-height: 1.5em;
  font-weight: 700;
  justify-self: end;
  grid-area: statusCreate;
}

.textGreen {
  color: var(--color-green);
}
