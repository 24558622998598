.wrapper {
  background: var(--color-bg-secondary);
  padding: 1em;
  position: absolute;
  z-index: 100;
  top: calc(100% + 0.8em); // 0.8 = pading nav + padding button
  left: -8em;
  right: -8em;
  box-shadow: 0 0 5px var(--color-shadow);
  border-radius: 0 0 5px 5px;

  // hides top shadow by standing on top of it
  &::after {
    content: '';
    display: block;
    height: 8px;
    background: var(--color-bg-secondary);
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
  }
}

.listButtons {
  justify-content: flex-end;
  display: flex;
}

.subtitle {
  font-weight: 700;
  padding: 0 0 0.2em 0;
}

.title {
  font-weight: 700;
  font-size: 1.4em;
  padding: 0 0 0.8em 0;
}

.wrapperInner {
  border-radius: 5px 0 5px 5px;
  padding: 1em;
  line-height: 1.5em;
  border: 2px solid var(--color-blue);
  background: var(--color-bg-reverse-secondary-primary);
  box-shadow: inset 0 0 5px var(--color-shadow);
}

.listItems {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 1.5em;
}
