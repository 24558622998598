.wrapper {
  width: 39em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto 1.5em 2.5em;
  grid-template-areas:
    'statusCreate statusJoin'
    'btnCreate btnJoin'
    '. .'
    'statusWs formId';
  gap: 0 2.5em;
}
