.wrapper {
  background: var(--color-bg-primary);
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 0.5em;
  box-shadow: inset 0 0 5px var(--color-shadow);
}

.link {
  color: var(--color-blue);
  text-decoration: none;

  &:hover {
    color: var(--color-blue-dark);
  }
}
