.wrapper {
  display: inline-block;
  text-align: center;
}

.btn {
  background: var(--color-blue);
  border: none;
  padding: 0.8em;
  margin-top: 0.5em;
  border-radius: 5px;
  transition: none;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 0 4px 1px var(--color-shadow);

  &:hover {
    outline: 2px solid var(--color-blue);
    outline-offset: 3px;
  }
}
