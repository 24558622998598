.menu {
  background: var(--color-bg-secondary);
  padding: 2.5em;
  border-radius: 5px;
  column-gap: 2.5em;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  box-shadow: 0 0 5px var(--color-shadow);
}
