.status {
  background: var(--color-bg-primary);
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-area: statusWs;
  box-shadow: inset 0 0 5px var(--color-shadow);
}

.connecting {
  color: var(--color-text);
}

.open {
  color: var(--color-green);
}

.closed {
  color: var(--color-red);
}

.error {
  color: var(--color-red);
  display: flex;
  flex-direction: column;
}

.btn {
  background: var(--color-green);
  padding: 0.5 0.5em;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  grid-area: btnStart;
  transition: none;

  &:hover,
  &:disabled {
    background: var(--color-green-dark);
  }
}
