/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/roboto-v29-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v29-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/roboto-v29-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v29-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/roboto-v29-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/roboto-v29-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v29-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/roboto-v29-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v29-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/roboto-v29-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/roboto-v29-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v29-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v29-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v29-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/roboto-v29-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/roboto-v29-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v29-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/roboto-v29-latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v29-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/roboto-v29-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
