@use '../../styles/variables';

.wrapperApp {
  height: 100%;
  background: var(--color-bg-primary);
  color: var(--color-text);
  overflow-y: scroll;

  @media screen and (max-width: variables.$size-screen-small) {
    // for the mobile overlay to not scroll
    overflow: hidden;
  }

  @media (min-width: variables.$size-screen-med) {
    font-size: 16px;
  }

  @media (min-width: variables.$size-screen-lg) {
    font-size: 18px;
  }

  @media (min-width: variables.$size-screen-xlg) {
    font-size: 22px;
  }
}
