.customization {
  padding: 1.5em 0 2.5em;
  border-radius: 5px;
  background: var(--color-bg-primary);
  grid-area: customization;
  box-shadow: inset 0 0 5px var(--color-shadow);
}

.wrapperOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperInner {
  display: flex;
  margin-top: 2em;
  gap: 12em;
}
