.form {
  grid-area: formId;
}

.input {
  background: var(--color-bg-primary);
  border-radius: 5px;
  text-align: center;
  border: none;
  height: 100%;
  color: var(--color-text);
  width: 100%;
  padding: 0.8em 0;
  box-shadow: inset 0 0 5px var(--color-shadow);

  &:focus {
    outline: 2px solid var(--color-blue);
  }

  &:invalid {
    outline: 1px solid var(--color-red);
  }
}
