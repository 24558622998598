.wrapper {
  position: absolute;
  top: calc(100% + 0.8em); // 0.8 = pading nav + padding button
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 5px var(--color-shadow);
  padding: 1em;
  background: var(--color-bg-secondary);
  white-space: nowrap;
  gap: 0.8em;

  // hides top shadow by standing on top of it
  &::after {
    content: '';
    display: block;
    height: 8px;
    background: var(--color-bg-secondary);
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    z-index: 300;
  }
}

.title {
  font-weight: 700;
  font-size: 1.4em;
  overflow: hidden;
}

.list {
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.link {
  box-shadow: inset 0 0 5px var(--color-shadow);
  display: block;
  text-decoration: none;
  border: none;
  padding: 0.5em;
  padding-left: 2em;
  background: none;
  cursor: pointer;
  text-align: left;
  transition: none;
  color: var(--color-text);
  background: var(--color-bg-primary);

  &:hover {
    opacity: 1;
    background: var(--color-blue);
    color: var(--color-white);
  }

  &:any-link {
    color: var(--color-text);
  }
}

.noSuggestions {
  font-style: italic;
  text-align: center;
  overflow: hidden;
  background: var(--color-bg-primary);
  padding: 0.7em;
  border-radius: 5px;
  font-size: 0.9em;
  box-shadow: inset 0 0 5px var(--color-shadow);
}
