.wrapperNotifications {
  padding: 1em;
  background: var(--color-bg-secondary);
  position: absolute;
  top: calc(100% + 0.8em); // 0.8 = pading nav + padding button
  left: -8em;
  right: -8em;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 5px var(--color-shadow);

  // hides top shadow by standing on top of it
  &::after {
    content: '';
    display: block;
    height: 8px;
    background: var(--color-bg-secondary);
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    z-index: 300;
  }
}

.title {
  font-weight: 700;
  font-size: 1.4em;
}

.noNotifications {
  font-style: italic;
  text-align: center;
  background: var(--color-bg-primary);
  padding: 0.7em;
  border-radius: 5px;
  font-size: 0.9em;
  box-shadow: inset 0 0 5px var(--color-shadow);
}

.wrapperHeading {
  padding-left: 0.5em;
}

.textHeading {
  font-size: 1.5em;
}
