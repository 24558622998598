@use '../../styles/variables';

.wrapper {
  display: none;

  @media screen and (max-width: variables.$size-screen-small) {
    position: fixed;
    padding: 1em;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-bg-primary);
    font-weight: 700;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1.4em;
    text-align: center;
  }
}
