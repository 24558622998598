.nav {
  background: var(--color-bg-secondary);
  display: flex;
  justify-content: space-between;
  padding: 0.6em 6em;
  z-index: 100;
  position: relative;
  box-shadow: 0 0 5px var(--color-shadow);
  align-items: stretch;
}

.nav__left {
  display: flex;
  align-items: center;
  gap: 1.5em;
}

.nav__right {
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.index,
.about,
.signin {
  text-decoration: none;
  color: var(--color-text);
  flex-shrink: 0;

  &:hover,
  &:focus {
    color: var(--color-blue);
    outline: none;
  }
}

.signin {
  padding: 0 1em 0 0.5em;
}

.signin,
.about {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  // text-transform: uppercase;
  // // font-size: 0.7em;
}

.about {
  transform: translateY(2px);
}

.index {
  font-size: 1.5em;
  font-weight: bold;
}
