// helpful styles
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  transition: background-color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1);
}

img {
  max-height: 100%;
}

button,
input,
select,
textarea {
  font-family: inherit;
  color: inherit;
  font-size: 100%;
}

body {
  font-family: Roboto, verdana, sans-serif;
  background: var(--color-bg-primary);
}

html,
body,
#root {
  height: 100%;
}
