.wrapper {
  display: flex;
  align-items: center;
  gap: 1em;
  border: 2px solid var(--color-red);
  padding: 0.5em 1em 0.5em 2em;
  position: absolute;
  z-index: 500;
  top: 0;
  left: 50%;
  transform: translate(-50%, -4.5em);
  transition: transform 250ms ease-in-out;
  pointer-events: none;
  background: var(--color-red);
  color: var(--color-white);
}

.visible {
  pointer-events: initial;
  transform: translate(-50%, 4.5em);
}

.btn {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  padding: 1em 0;

  &:hover {
    opacity: 1;
  }
}

.cross {
  width: 1.5em;
  height: 3px;
  background-color: var(--color-white);
  position: relative;
  transform: rotate(45deg);

  &::after {
    content: '';
    display: block;
    width: 1.5em;
    height: 3px;
    background-color: var(--color-white);
    position: absolute;
    transform: rotate(90deg);
  }
}
