.wrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

.wrapper--active {
  box-shadow: inset 0 0 5px var(--color-shadow);
  background: var(--color-bg-primary);
}

.button {
  padding: 0.4em 0.5em;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  filter: var(--color-icon);
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.counter {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6em;
  height: 1.6em;
  font-size: 0.7em;
  font-weight: 700;
  padding: 0.3em;
  border-radius: 50%;
  background: var(--color-blue);
  color: var(--color-white);
  top: 50%;
  left: 50%;
  opacity: 1;
}
