.form {
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 0.5em;
  align-items: center;
}

.input {
  width: 3.5em;
  padding: 0.3em 0;
  border-radius: 5px;
  background: var(--color-bg-secondary);
  border: none;
  text-align: center;
  box-shadow: 0 0 5px var(--color-shadow);

  &:focus {
    outline: 2px solid var(--color-blue);
  }

  &:invalid {
    outline: 1px solid var(--color-red);
  }
}

.tooltip {
  position: relative;

  &::after {
    display: block;
    white-space: nowrap;
    content: attr(data-tooltip);
    position: absolute;
    left: 115%;
    top: 0.45em;
    z-index: 2;
    position: absolute;
    opacity: 0;
    color: var(--color-red);
    transition: opacity 100ms ease-in-out;
    pointer-events: none;
  }

  &:hover::after,
  &:focus-within::after {
    opacity: 1;
  }
}
