.wrapper {
  display: flex;
  justify-content: center;
}

.loader {
  color: var(--color-green);
  background-color: var(--color-bg-secondary);
  font-size: 1.3em;
  padding: 0.5em 1em;
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
