.wrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

.wrapper--active {
  box-shadow: inset 0 0 5px var(--color-shadow);
  background: var(--color-bg-primary);
}

.button {
  padding: 0.4em 0.5em;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  filter: var(--color-icon);
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
