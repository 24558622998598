.inputToggle {
  /* visually hidden but still accessible */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.btnToggle {
  cursor: pointer;
  width: 80px;
  height: 40px;
  display: block;
  border-radius: 100px;
  position: relative;
  background: var(--color-bg-primary);
  box-shadow: inset 0 0 5px var(--color-shadow);
  flex-shrink: 0;
  user-select: none;
}

.btnToggle:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: var(--color-blue);
  border-radius: 90px;
  transition: 0.3s ease-in-out;
}

.btnToggleOn:after {
  transform: translateX(40px);
}

.wrapperIcons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
}

.iconMoon,
.iconSun {
  width: 25px;
  height: 25px;
}
