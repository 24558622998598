.wrapper {
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

.wrapper--visible {
  gap: 0.5em;
  box-shadow: inset 0 0 5px var(--color-shadow);
  background: var(--color-bg-primary);
}

.button {
  padding: 0.4em 0.5em;
  border: none;
  background: none;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}

.icon {
  filter: var(--color-icon);
}

.search {
  background: none;
  border: none;
  width: 0;
  transition: width 200ms ease-in-out;
  padding: 0;

  &::placeholder {
    color: var(--color-text);
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.search--visible {
  width: 9em;
}
